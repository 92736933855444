import {
  Avatar,
  Button,
  Callout,
  Checkbox,
  Flex,
  IconButton,
} from "@radix-ui/themes";
import {
  addCustomToken,
  setInputToken,
  setOutputToken,
  setSwapStep,
  SwapStep,
} from "@/app/features/swap";
import BackIcon from "@/common/icons/BackIcon";
import RefreshIcon from "@/common/icons/RefreshIcon";
import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "@/app/hooks";
import { TokenList } from "@/app/features/swap/components/TokenList";
import {
  useInputToken,
  useOutputToken,
  useSwapStep,
} from "@/app/features/swap/slice";
import { shortenEthAddress } from "@/common/utils";
import CopyButton from "../../../../../../common/components/CopyButton/CopyButton";
import Link from "next/link";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { useNetwork } from "wagmi";

export default function CustomPage() {
  const dispatch = useAppDispatch();
  const setPage = (swapStep: SwapStep) => () => dispatch(setSwapStep(swapStep));
  const swapStep = useSwapStep();
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const isInputToken = useMemo(
    () => swapStep === SwapStep.CUSTOM_INPUT_TOKEN,
    [swapStep],
  );
  const token = useMemo(
    () => (isInputToken ? inputToken : outputToken),
    [isInputToken, inputToken, outputToken],
  );

  const { chain } = useNetwork();
  const [checked, setChecked] = useState(false);

  const cancel = () => {
    dispatch(isInputToken ? setInputToken() : setOutputToken());
    dispatch(setSwapStep(SwapStep.SWAP));
  };

  const handleAddCustomToken = useCallback(() => {
    if (!token) return;
    dispatch(addCustomToken(token));
    dispatch(setSwapStep(SwapStep.SWAP));
  }, [dispatch, token]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full items-center justify-between pb-1 text-xl">
        <IconButton
          variant="ghost"
          size="1"
          color="gray"
          onClick={setPage(SwapStep.SWAP)}
        >
          <BackIcon />
        </IconButton>
        <span className="text-lg font-semibold">Import a token</span>
        <IconButton variant="ghost" size="1" color="gray" className="opacity-0">
          <RefreshIcon />
        </IconButton>
      </div>
      <Flex className="justify-between gap-2 rounded-xl px-4 py-3 shadow-[0_0_0_1px_color-mix(in_oklab,_var(--gray-a5),_var(--gray-5)_25%)]">
        <div className="flex items-center gap-2">
          <Avatar
            fallback={token?.symbol[0] || ""}
            src={token?.logoURI}
            radius="full"
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <div className="text-sm font-bold">{token?.name}</div>
              <div className="text-xs text-grayA-11">{token?.symbol}</div>
            </div>
            <div className="flex items-center gap-1 text-xs text-grayA-11">
              {shortenEthAddress(token?.address || "")}
              <CopyButton content={token?.address || ""} />
            </div>
          </div>
        </div>
        <div className="flex items-end text-xs font-medium text-grayA-11">
          <Link
            href={`${chain?.blockExplorers?.default.url}/token/${token?.address}`}
            target="_blank"
            className="flex items-center gap-1 hover:underline"
          >
            {chain?.blockExplorers?.default.name}
            <ExternalLinkIcon />
          </Link>
        </div>
      </Flex>

      <Callout.Root className="flex items-center justify-between">
        <Callout.Text>
          <div className="text-lg">
            <div>Make sure you have checked the</div>
            <div>{`token address and it's correct`}</div>
          </div>
        </Callout.Text>
        <img src="/froge.png" height={117} width={117} alt="froge logo" />
      </Callout.Root>

      <Callout.Root color="red">
        <Callout.Text>
          <div>
            Anyone has the capability to create various tokens, including
            counterfeit versions of already existing ones. Exercise caution.
            Certain tokens and their specific technical features might not be
            compatible with FrogeSwap services. By importing a custom token, you
            recognize and agree to the associated risks.
          </div>
          <label className="mt-2 flex cursor-pointer items-center gap-2 font-medium">
            <Checkbox
              size="2"
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
            Accept
          </label>
        </Callout.Text>
      </Callout.Root>
      <div className="flex w-full gap-2">
        <Button className="swap-button flex-1" onClick={cancel} variant="soft">
          Cancel
        </Button>
        <Button
          className="swap-button flex-1"
          disabled={!checked}
          onClick={handleAddCustomToken}
        >
          Import
        </Button>
      </div>
    </div>
  );
}
