import { Button, Callout, IconButton } from "@radix-ui/themes";
import RefreshIcon from "@/common/icons/RefreshIcon";
import BackIcon from "@/common/icons/BackIcon";
import {
  resetSwapState,
  setSwapStep,
  SwapStep,
  useInputToken,
  useOutputToken,
  useSlippage,
} from "@/app/features/swap/slice";
import { useGasPrice, useSwap } from "@/app/features/swap/hooks";
import { useAppDispatch } from "@/app/hooks";
import { formatEther } from "viem";
import React from "react";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { TokenInfo } from "@/app/features/swap/components/TokenInfo";
import { useSendTransaction, useWaitForTransaction } from "wagmi";
import ConfirmTsx from "@/app/features/swap/components/ConfirmTsx/ConfirmTsx";
import WaitTsx from "@/app/features/swap/components/WaitTsx/WaitTsx";
import ErrorTsx from "@/app/features/swap/components/ErrorTsx/ErrorTsx";
import { onError } from "@/app/features/toaster/utils";
import { notifySuccess } from "@/app/features/toaster";

export default function ConfirmSwap({ isLoading = false }) {
  const dispatch = useAppDispatch();
  const {
    inputTotalPrice,
    outputTotalPrice,
    priceImpact,
    inputAmount,
    outputAmount,
    isLoading: isSwapLoading,
    refetch,
    nativePrice,
    error,
    swap,
  } = useSwap(isLoading);
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const slippage = useSlippage();
  const gasPrice = useGasPrice(swap?.gas);
  const setPage = (swapStep: SwapStep) => () => dispatch(setSwapStep(swapStep));
  const {
    sendTransaction,
    data,
    isLoading: isPending,
    reset,
  } = useSendTransaction(
    swap
      ? {
          ...swap.tx,
          gasPrice: BigInt(swap.tx.gasPrice),
          value: BigInt(swap.tx.value),
          gas: BigInt(swap.tx.gas),
          onError: onError("Swap", dispatch),
        }
      : {},
  );

  const {
    isLoading: isTsxWaiting,
    isError: isWaitingError,
    error: waitingError,
  } = useWaitForTransaction({
    hash: data?.hash,
    onError: onError("Swap", dispatch),
    onSuccess: (transaction) => {
      dispatch(resetSwapState());
      notifySuccess({
        hash: transaction.transactionHash,
        func: "Swap",
        dispatch,
      });
      setPage(SwapStep.SWAP)();
    },
  });

  return (
    <>
      {isPending && <ConfirmTsx reset={reset} />}
      {isTsxWaiting && <WaitTsx hash={data?.hash} />}
      {isWaitingError && (
        <ErrorTsx
          hash={data?.hash}
          error={waitingError}
          reset={reset}
          retry={() => sendTransaction?.()}
        />
      )}
      <div className="flex flex-col gap-3">
        <div className="flex w-full items-center justify-between pb-1 text-xl">
          <IconButton
            variant="ghost"
            size="1"
            color="gray"
            onClick={setPage(SwapStep.SWAP)}
          >
            <BackIcon />
          </IconButton>
          <span className="text-lg font-semibold">Confirm Swap</span>
          <IconButton
            variant="ghost"
            size="1"
            color="gray"
            disabled={isSwapLoading}
            onClick={() => refetch?.()}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        {inputToken && (
          <TokenInfo
            token={inputToken}
            price={inputTotalPrice}
            priceImpact={priceImpact}
            inputTotalPrice={inputTotalPrice}
            outputTotalPrice={outputTotalPrice}
            isLoading={!error && (isLoading || isSwapLoading)}
            amount={inputAmount}
          />
        )}
        {outputToken && (
          <TokenInfo
            token={outputToken}
            price={outputTotalPrice}
            priceImpact={priceImpact}
            inputTotalPrice={inputTotalPrice}
            outputTotalPrice={outputTotalPrice}
            isLoading={!error && (isLoading || isSwapLoading)}
            isOutput
            amount={outputAmount}
          />
        )}

        {error && error.status !== 429 && (
          <Callout.Root color="red">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>{error.data.description}</Callout.Text>
          </Callout.Root>
        )}

        {error && error.status === 429 && (
          <Callout.Root color="red">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>Fetch failed please retry</Callout.Text>
          </Callout.Root>
        )}

        {!error && (
          <>
            <div className="flex flex-col gap-2 rounded-lg bg-grayA-2 p-4 text-[13px] font-medium">
              <div className="flex justify-between">
                <span className="text-grayA-11">Slippage tolerance</span>
                <span
                  className={clsx({
                    "flex h-[13px] w-48 animate-pulse rounded bg-gray-4":
                      isSwapLoading,
                  })}
                >
                  {!isSwapLoading && `${slippage}%`}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-grayA-11">Minimum receive</span>
                <span
                  className={clsx({
                    "flex h-[13px] w-48 animate-pulse rounded bg-gray-4":
                      isSwapLoading,
                  })}
                >
                  {!isSwapLoading &&
                    `${
                      Number(outputAmount) -
                      Number(outputAmount) * (slippage / 100)
                    } ${outputToken?.symbol}`}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-grayA-11">Network fee</span>
                <span
                  className={clsx({
                    "flex h-[13px] w-48 animate-pulse rounded bg-gray-4":
                      isSwapLoading,
                  })}
                >
                  {!isSwapLoading &&
                    `$${(
                      Number(nativePrice) * Number(formatEther(gasPrice || 0n))
                    ).toFixed(2)}`}
                </span>
              </div>
            </div>
            {isSwapLoading ? (
              <Button className="swap-button" disabled>
                <span className="flex h-6 w-1/2 animate-pulse rounded bg-gray-5"></span>
              </Button>
            ) : (
              <Button
                className="swap-button"
                onClick={() => sendTransaction?.()}
              >
                Swap
              </Button>
            )}
          </>
        )}
        {error && error.status === 429 && (
          <Button
            className="swap-button"
            color="red"
            onClick={() => refetch?.()}
          >
            Retry
          </Button>
        )}
        {error && error.status !== 429 && (
          <Button
            className="swap-button"
            color="red"
            onClick={setPage(SwapStep.SWAP)}
          >
            Adjust order
          </Button>
        )}
      </div>
    </>
  );
}
