import * as Accordion from "@radix-ui/react-accordion";
import { useQuote, useQuoteCanSwap } from "@/app/features/swap/hooks";
import { Flex, IconButton } from "@radix-ui/themes";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { useMemo, useState } from "react";
import {
  useInputToken,
  useOutputToken,
  useSlippage,
} from "@/app/features/swap/slice";
import { formatEther } from "viem";
import GasIcon from "@/common/icons/GasIcon";
export default function SwapInfo({ isLoading = false }) {
  const slippage = useSlippage();
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const { inputAmount, outputAmount, nativePrice } = useQuote(isLoading);
  const [swap, setSwap] = useState(false);
  const { gasPrice } = useQuoteCanSwap(isLoading);
  const ratio = useMemo(() => {
    const val =
      Number((swap ? outputAmount : inputAmount) || "0") /
      Number((swap ? inputAmount : outputAmount) || "");

    return val >= 1
      ? val.toLocaleString()
      : val.toFixed(
          swap ? outputToken?.decimals || 18 : inputToken?.decimals || 18,
        );
  }, [
    inputAmount,
    inputToken?.decimals,
    outputAmount,
    outputToken?.decimals,
    swap,
  ]);
  if (
    !inputAmount ||
    !outputAmount ||
    !inputToken ||
    !outputToken ||
    Number(outputAmount) <= 0
  )
    return null;
  return (
    <Flex direction="column" gap="3">
      <Accordion.Root className="" type="single" collapsible>
        <Accordion.Item
          className="overflow-hidden rounded-lg bg-grayA-2"
          value="item-1"
        >
          <Accordion.Header className="flex items-center justify-between px-4 py-2">
            <span
              role="button"
              className="cursor-pointer text-[13px] font-medium"
              onClick={() => !isLoading && setSwap(!swap)}
            >{`1 ${swap ? inputToken.symbol : outputToken.symbol} = ${ratio} ${
              !swap ? inputToken.symbol : outputToken.symbol
            }`}</span>
            <Accordion.Trigger className="data-[state='open']:[--gas-opacity:0] data-[state='open']:[--rotate-chevron:180deg]">
              <span className="flex w-full items-center gap-2">
                {gasPrice > 0n && nativePrice && (
                  <span className="flex items-center gap-1 text-[13px] font-medium opacity-[var(--gas-opacity,1)] transition-opacity">
                    <span className="text-base">
                      <GasIcon />
                    </span>
                    {`$${(
                      Number(nativePrice) * Number(formatEther(gasPrice || 0n))
                    ).toFixed(2)}`}
                  </span>
                )}
                <IconButton color="gray" variant="soft" asChild>
                  <span>
                    <ChevronDownIcon
                      className="rotate-[var(--rotate-chevron,0)]"
                      aria-hidden
                    />
                  </span>
                </IconButton>
              </span>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="data-[state='open']:animate-slideDown data-[state='closed']:animate-slideUp text-[13px] font-medium">
            <div className="flex flex-col gap-2 px-4 pb-4 pt-2">
              <div className="flex justify-between">
                <span className="text-grayA-11">Slippage tolerance</span>
                <span>{`${slippage}%`}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-grayA-11">Minimum receive</span>
                <span>{`${
                  Number(outputAmount) - Number(outputAmount) * (slippage / 100)
                } ${outputToken?.symbol}`}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-grayA-11">Network fee</span>
                <span>{`$${(
                  Number(nativePrice) * Number(formatEther(gasPrice || 0n))
                ).toFixed(2)}`}</span>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </Flex>
  );
}
